<template>
  <div class="page">
    <Viewheader :isHome="false" ref="navheader" />
    <!-- banner图 -->
    <BannerHead :bannerImg="bannerImg" :title="bigTitle_1" :desc="smallTitle_1">
      <div class="header-custom" slot="content">
        <!-- <div class="header-1">

        </div> -->
        <img class="earphone" src="@/assets/img/titleAnalysis/banner-1.png" />
        <img class="header-1" src="@/assets/img/titleAnalysis/banner-2.png" />
        <img class="header-2" src="@/assets/img/titleAnalysis/banner-3.png" />
        <img class="header-6" src="@/assets/img/titleAnalysis/banner-7.png" />
        <img class="header-4" src="@/assets/img/titleAnalysis/banner-5.png" />
        <img class="header-5" src="@/assets/img/titleAnalysis/banner-6.png" />
        <img class="header-3" src="@/assets/img/titleAnalysis/banner-4.png" />
        <img class="header-7" src="@/assets/img/titleAnalysis/banner-8.png" />
      </div>
    </BannerHead>
    <!-- 核心功能 -->
    <div class="core-function">
      <CommonTitle title="核心功能" />
      <ColumuTwo
        class="core-function_main core-function_main_top"
        :list="spanList[0]"
        :img="coreImg"
        theme="light"
      ></ColumuTwo>
      <div class="core-loading">
        <img src="@/assets/img/titleAnalysis/loading-white.png" alt="" />
      </div>
      <div class="core-function_main_bottom">
        <img src="@/assets/img/titleAnalysis/core-bg2.png" alt="" />
        <ul>
          <li>
            <SectionArticle
              class="common-two-column_text-item"
              v-for="(item, index) in spanList[1]"
              :key="index"
              :title="item.title"
              :desc="item.desc"
            />
          </li>
          <li>hoodie</li>
          <li>essentials hoodie</li>
          <li>purple hoodie</li>
        </ul>
      </div>
    </div>
    <!-- 能力展示 -->
    <div class="similar-main-wrap">
      <div class="similar-main">
        <CommonTitle title="能力展示" :desc="ability.desc" />
        <div class="entity_class">
          <div class="entity_clas1">
            <!-- 请输入商品文本 -->
            <div class="entity_clas2">
              <p>请输入商品文本:<a @click="randomClickShow">随机示例</a></p>
              <el-form
                :model="formText"
                class="entityClass"
                :class="{ frosted_glassed: isActive }"
              >
                <el-form-item class="entityClass_1">
                  <el-input
                    type="textarea"
                    resize="none"
                    v-model="formText.texts"
                    placeholder="请输入完整英文商品标题；知悉场景提示；"
                    class="entityInput"
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-form>

              <!-- <el-form-item class="entityClass_2"> -->
              <el-button
                type="primary"
                @click="submitTexts('formText')"
                class="entityBtn"
                >开始处理</el-button
              >
              <!-- </el-form-item> -->

              <div class="msg-tipsd" style="display: none" id="msgTip">
                文本解析中...
              </div>
              <div class="msg-tipsd" style="display: none" id="msgTips">
                很抱歉，文本中可能存在敏感内容,请<span class="retry"
                  >重新输入</span
                >。
              </div>
            </div>

            <!-- 处理结果 -->
            <div class="entity_clas2">
              <p>处理结果：</p>
              <div class="txt_class_extract">
                <span>产品词 : {{ productWords }}</span>
                <span>产品修饰词 : {{ modifierWords }}</span>
                <span>适用场景 : {{ sceneWords }} </span>
                <span>SEO商品词keyword挖掘 : {{ keyWords }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 差异优势 -->
    <!-- <Different :diffImg="diffImg" :diffList="diffList"></Different> -->

    <!-- 应用场景 -->
    <div class="application-scene">
      <CommonTitle title="应用场景" />
      <ColumuTwo
        class="application-scene_main"
        :img="appImg"
        :list="appliList[0]"
      >
      </ColumuTwo>
      <div class="application-loading">
        <img src="@/assets/img/titleAnalysis/loading-white.png" alt="" />
      </div>
      <ColumuTwo
        class="application-scene_main application-scene_main_bottom"
        :list="appliList[1]"
        :reverse="true"
        :img="appImg2"
        theme="light"
      >
      </ColumuTwo>
    </div>

    <!-- 尾部 -->
    <Footering />
  </div>
</template>

<script>
// banner
import BannerHead from './components/Banner.vue';
import CommonTitle from './components/CommonTitle.vue';
import ColumuTwo from './components/ColumuTwo.vue';
import SectionArticle from './components/SectionArticle.vue';
import Different from '@/components/centerPage/differential_adv.vue';

import cha16 from '@/assets/img/gongneng/keyword/changjing.png';
import googleLogo from '@/assets/img/imgSimilar/google.png';
import en from '@/assets/img/titleAnalysis/ability-en.png';

// 公用组件
import '../../assets/css/public.css';
import '../../assets/css/normalize.css';
import '../../assets/css/commonModule.css';

import { getKeyword } from '@/api/aidata.js';

// 头部
import Viewheader from '../../components/viewheader/viewheader.vue';
// foote组件
import Footering from '../../components/foot/footer.vue';

export default {
  components: {
    BannerHead,
    CommonTitle,
    ColumuTwo,
    SectionArticle,
    Different,
    Viewheader,
    Footering,
  },
  data() {
    return {
      bannerImg: require('@/assets/img/chatgpts/bg.png'),
      coreImg: require('@/assets/img/titleAnalysis/core-1.png'),
      bigTitle_1: '标题解析及<span style="color: #6C38E0;">keyword</span>挖掘',
      smallTitle_1:
        '解析标题中的商品词、描述词、场景词等，并针对商品词进行相关“长尾词”挖掘',
      spanList: [
        [
          {
            title: '标题信息解析',
            desc: '目前可解析出标题中的商品词、品牌词、颜色、人群、材质、型号、适用场景等12种关键词。',
          },
        ],
        [
          {
            title: '商品词keyword挖掘',
            desc: '对标题中解析出的商品词进行keyword挖掘。',
          },
        ],
      ],
      diffImg: cha16,
      diffList: [
        {
          diffFirst: '跨境场景数据训练',
          diffSecond:
            '提升图⽂相关性的多模态模型，利⽤近7000w跨境电商数据训练。对跨境数据集能够更好的识别效果。',
        },
        {
          diffFirst: '标题⻓尾词布局',
          diffSecond:
            '已在⾃身业务shopping、seo流量场景落地应⽤，并在单item引流uv、item均gmv等指标上 提升10%+。',
        },
      ],
      appImg: require('@/assets/img/titleAnalysis/app-1.png'),
      appImg2: require('@/assets/img/titleAnalysis/app-2.png'),
      appliList: [
        [
          {
            title: 'SEO商品词提取及keyword挖掘',
            desc: '在seo场景，基于标题的商品词抽取及基于商品词进行keyword扩展，让每个词引入的流量都有承接品。同时，可基于商品的引流效率及转化 反馈词库更新。',
          },
        ],
        [
          {
            title: '商品标签补充',
            desc: '通过对于标题的解析，补充商品标签，提升商品理解。',
          },
        ],
      ],
      // 能力展示
      ability: {
        desc: `模型⽬前仅⽀持<img src='${en}' /><span style='color:#6C38E0'>英⽂</span>标题，其他语种需进⾏翻译后处理。模型结果对<img src='${googleLogo}' /><span style='color:#6C38E0'>google 系列产品（shopping/⾕歌seo等）</span>，进⾏了针对性优化，在此场景下使⽤效果更优。`,
      },
      formText: {
        texts:
          'Beach Bag Casual Rattan Large Capacity Totes Designer Wicker Woven Women Handbags Summer Beach Bali Straw Bags Lady Travel Big Basket Purse',
      },
      productWords: '',
      modifierWords: '',
      sceneWords: '',
      keyWords: '',
      isActive: false,
      randomList: [
        {
          title:
            '2022-Designer denim Handbags Purses Large Capacity Shopping Bag Women Totes Travel New Fashion Shoulder Bags Crossbody canvas',
          proKeys1: '',
          proKeys2: '',
          address: '',
          seoKey: '',
        },
        {
          title:
            'Beach Bag Casual Rattan Large Capacity Totes Designer Wicker Woven Women Handbags Summer Beach Bali Straw Bags Lady Travel Big Basket Purse',
          proKeys1: '',
          proKeys2: '',
          address: '',
        },
        {
          title:
            'Casual shoes women Travel leather lace-up sneaker 100% cowhide fashion lady Flat designer Running Trainers Letters woman shoe platform men gym sneakers size 35-42-45',
          proKeys1: '',
          proKeys2: '',
          address: '',
          seoKey: '',
        },
        {
          title:
            'big size us 13 boots Designer Authentic Air Mag Back To The Future Glow In Dark Gray Sneakers Marty Mcfly Led Shoes Lighting Up Mags Sneake designer sneaker mens no logo',
          proKeys1: '',
          proKeys2: '',
          address: '',
        },
        {
          title:
            'V1 Usb Robot Vacuum Cleaner Electric Sweeper Household Sweeping Machine Automatic Recharge Cleaning Appliances',
          proKeys1: '',
          proKeys2: '',
          address: '',
          seoKey: '',
        },
      ],
    };
  },
  computed: {},
  mounted() {},
  methods: {
    /**
     * @description: 提交文本
     * @return {*}
     */
    submitTexts() {
      // this.$message({
      //   message: '功能尚未开放',
      //   type: 'info',
      // });
      // return;
      if (this.formText.texts == '') {
        // return this.$message.error("请输入内容");
        this.$message({
          message: '请输入内容',
          type: 'error',
        });
        return;
      }
      this.isActive = true;
      document.getElementById('msgTip').style.display = 'block';
      getKeyword({
        title: this.formText.texts,
      }).then(({ data }) => {
        if (data.state === '0x0000') {
          setTimeout(() => {
            this.isActive = false;
            document.getElementById('msgTip').style.display = 'none';
            // this
            this.productWords = data.data.productWords;
            this.modifierWords = data.data.modifierWords;
            this.sceneWords = data.data.sceneWords;
            this.keyWords = data.data.keyWords;
          }, 1000);
        } else if (data.state == '0x0008') {
          // this.$message.error(data.message);
          this.$message({
            message: data.message,
            type: 'error',
          });
          this.$refs.navheader.logOn();
        } else {
          this.isActive = false;
          document.getElementById('msgTip').style.display = 'none';
          this.$message({
            message: data.message,
            type: 'error',
          });
          // return this.$message.error(data.message);
        }
      });
    },

    /**
     * @description: 随机示例
     * @return {*}
     */
    randomClickShow() {
      var index = Math.floor(Math.random() * 5);
      this.formText.texts = this.randomList[index].title;
      // this.productWords = this.randomList[index].proKeys1;
      // this.modifierWords = this.randomList[index].proKeys2;
      // this.sceneWords = this.randomList[index].address;
      // this.keyWords = this.randomList[index].seoKey;
    },
  },
};
</script>

<style lang="less">
a {
  cursor: pointer;
}

.entity_class {
  width: 1280px;
  height: fit-content;
  box-sizing: border-box;
  background: #fff;
  border-radius: 16px;
  margin: 0 auto;
  padding: 24px;
  box-sizing: border-box;
}

.entityInput .el-textarea__inner {
  height: 286px;
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
  border: none;
  padding: 16px 20px;
  color: #000108;
  font-size: 14px;
}

.entity_clas2 p {
  margin: 0;
  font-size: 16px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #333333;
  line-height: 16px;
  margin-bottom: 24px;
}

.entity_clas2 p a {
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #6c38e0;
  line-height: 16px;
  text-decoration: underline;
  margin-left: 19px;
}
.entityClass {
  border: 1px solid #c5c5cb;
  border-radius: 8px;
}

.entity_clas1 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 50px;
  position: relative;
}

.entityClass_1 {
  margin-bottom: 15px !important;
}

.entityClass_2 {
  margin-bottom: 0 !important;
}

.entity_clas2 {
  flex: 1;
  flex-shrink: 0;
  &:first-child {
    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 2px;
      background: #f0f2f5;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .el-button {
    position: relative;
    margin-top: 69px;
    margin-right: 11px;
    margin-left: auto;
    left: 100%;
    transform: translateX(-100%);
    background: #6c38e0;
    border-radius: 4px;
    padding: 13px 52px;
  }
}

.txt_class_extract {
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-top: 36px;
  font-size: 14px;
  text-align: left;
  // width: 512px;
  height: 282px;
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
}

.txt_class_extract span {
  margin-bottom: 36px;
  display: block;
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
  word-break: break-all;
}

.el-button--primary {
  background: #6567e1 linear-gradient(180deg, #361ecd 0%, #777aed 0%);
  border: none;
}

.el-button--primary:hover {
  background: #6567e1 linear-gradient(180deg, #361ecd 0%, #777aed 0%);
}

.frosted_glassed {
  pointer-events: none;
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius="3");
}

.msg-tipsd {
  position: absolute;
  /* left: 37%;
  top: 50%; */
  -webkit-transform: translate(120%, -500%);
  -moz-transform: translate(120%, -500%);
  -ms-transform: translate(120%, -500%);
  transform: translate(120%, -500%);
  padding: 0 32px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 36px;
  line-height: 36px;
  color: #666;
  background: #fff;
  -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.entityInput .el-input__count {
  right: 130px;
}
@media screen and(max-width:1280px) {
  .entity_class {
    width: 100%;
  }
}
@media screen and (max-width: 990px) {
  .entity_class {
    width: 100%;
    height: auto;
    padding: 0px;
  }
  .entity_clas1 {
    flex-direction: column;
    width: auto;
    padding: 20px 10px;
  }
  .entity_clas2 {
    width: 100%;
    padding-bottom: 20px;
    &:first-child:after {
      display: none;
    }
    .entityBtn {
      margin: 20px;
      margin-left: auto;
    }
  }
  .entityClass {
    margin: 0;
  }

  .el-textarea__inner {
    width: 100% !important;
  }
  .txt_class_extract {
    width: 100%;
  }
  .el-form-item__content {
    /* text-align: right; */
    /* margin-left: 10px; */
    /* float: right; */
  }

  .entity_main {
    width: 93%;
    margin: auto;
  }
  .msg-tipsd {
    position: absolute;
    /* left: 37%;
    top: 50%; */
    -webkit-transform: translate(100%, -500%);
    -moz-transform: translate(100%, -500%);
    -ms-transform: translate(100%, -500%);
    transform: translate(100%, -500%);
    padding: 0 32px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 36px;
    line-height: 36px;
    color: #666;
    background: #fff;
    -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
}
</style>
<style lang="less" scoped>
.header-custom {
  position: absolute;
  height: 100%;
  img {
    position: absolute;
    height: 8%;
  }
  .earphone {
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 17.7%;
    height: auto;
  }
  .header-1 {
    width: 26.1%;
    height: auto;
    bottom: 21.2%;
    left: 5%;
  }
  .header-2,
  .header-5 {
    bottom: 26%;
    right: 10%;
  }
  .header-3,
  .header-6 {
    bottom: 15%;
    right: 5%;
  }
  .header-4,
  .header-7 {
    bottom: 3%;
    right: 5%;
  }
  @time: 8s;
  .header-2,
  .header-3,
  .header-4 {
    animation: fadeOut @time ease-in-out 1s 3 forwards;
  }
  .header-5,
  .header-6,
  .header-7 {
    opacity: 0;
    animation: fadeIn @time ease-in-out 1s 3 forwards;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 1;
    }
    35% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    15% {
      opacity: 0;
    }
    35% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
}
.core-function_main,
.application-scene_main,
.different-advantage_main {
  margin-top: 80px;
}
/* 核心 */
.core-function {
  padding: 80px 0 80px;
  .core-custom {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 103.75%;
    margin-left: -1.875%;
    font-family: PingFang SC;
    font-size: 24px;
    color: #fff;
    background: #6c38e0;
    border-radius: 16px;
    padding: 13px 13px 60px 24px;
    margin-top: 131px;
    box-sizing: border-box;
  }
  .core-custon_person {
    position: absolute;
    width: 210px;
    left: 50%;
    top: -55%;
    bottom: auto;
    transform: translateX(-50%);
  }
  .core-custon_text {
    padding-top: 123px;
    flex-shrink: 0;
    margin-right: 23px;
    span {
      display: block;
      font-size: 14px;
      margin-top: 8px;
    }
  }
  .core-custom_diy {
    flex-shrink: 0;
    padding-top: 64px;
    margin-right: 12px;
    width: 42.3%;
    img {
      width: 100%;
    }
  }
  .core-custom_IM {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: -240px;
    img {
      width: 100%;
    }
  }

  .core-function_main_top {
    position: relative;
    margin-top: 40px;
    width: 100%;
    height: fit-content;
    border-radius: 16px;
    padding: 111px 0 0 80px;
    box-sizing: border-box;
    &::before {
      content: '';
      width: 100%;
      left: 0;
      top: 0;
      z-index: 0;
      position: absolute;
      padding-bottom: 37.5%;
      background-image: url('../../assets/img/titleAnalysis/core-bg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .core-function_main_bottom {
    position: relative;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 16px;
    background: #ff8645;
    overflow: hidden;
    margin-top: 40px;

    img {
      width: 100%;
      transition: all 0.3s;
      // &:hover {
      //   transform: scale(1.2);
      // }
    }
    ul {
      width: calc(100% - 48px);
      position: absolute;
      display: flex;
      color: #fff;
      font-size: 20px;
      column-gap: 3.52%;
      bottom: 4.6%;
      left: 24px;
      align-items: center;
    }
    li {
      width: 12.5%;
      white-space: pre-wrap;
      word-break: break-all;
      &:first-child {
        background: #fff;
        border-radius: 8px;
        padding: 1.9% 0.94%;
        flex: 1;
      }
      &:nth-child(3) {
        width: 14.1%;
        flex-shrink: 0;
      }
    }
  }
  /deep/ .common-two-column_img {
    width: 55.6%;
    margin-top: -181px;
    margin-right: 0;
    overflow: inherit;
    img {
      position: relative;
    }
  }
  /deep/ .common-two-column {
    column-gap: 0;
  }
}
/* 应用场景 */
.application-scene {
  padding-top: 80px;
  padding-bottom: 120px;

  /deep/ .common-two-column {
    column-gap: 57px;
  }
  /deep/ .common-two-column_img {
    width: 39.8%;
    margin-right: 55px;
    margin-top: -136px;
  }
  .application-scene_main_bottom {
    margin-top: -2.5%;
    background-image: url('../../assets/img/titleAnalysis/app-bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 6px 0 23px;
    /deep/ .common-two-column_img {
      width: 39.8%;
      margin-right: 55px;
      margin-top: -0;
    }
    /deep/ .section-article {
      margin-top: 170px;
    }
  }
}
.core-loading,
.application-loading {
  position: relative;
  width: 2.81%;
  background: #ff8645;
  padding: 2.1% 1.96%;
  border-radius: 100px;
  margin: auto;
  img {
    width: 100%;
    height: 100%;
  }
  z-index: 1;
}
.core-loading {
  margin: -2.8% auto 0;
}
.application-loading {
  margin-top: 40px;
}

// 能力展示
.similar-main-wrap {
  padding: 80px 0;
  width: 100%;
  background: #f0f2f5;
  /deep/ .common-text {
    img {
      vertical-align: middle;
      display: inline-block;
      margin: -2px 3px 0;
      width: 24px;
    }
  }
  .operationBackgroundColor {
    height: calc(100% - 62px);
  }
  .similarityGraph {
    width: 32.8%;
    background: #d8d8d8;
    border-radius: 8px;
  }
  .operationCenter {
    width: 71.25%;
    height: auto;
    margin-left: 16px;
    img {
      max-width: 100%;
      max-height: 100%;
      width: fit-content;
    }
  }
  .successImgProduct {
    width: 100%;
  }
  .operationYellowRight {
    flex: 1;
    flex-shrink: 0;
    height: 100%;
    margin: 7px 0 0 24px;
  }
  .recognitionResult {
    margin: 0;
    font-size: 18px;
    color: #000108;
    span {
      color: #ff8645;
    }
  }
  .detectionButton {
    background: rgba(108, 56, 224, 0.1);
    color: rgba(108, 56, 224, 1);
  }
  .confidenceLevel {
    margin: 24px 0 0;
    font-size: 14px;
  }
  .similarityGraphMain {
    height: 100%;
    justify-content: space-between;
    row-gap: 0.8%;
    column-gap: 0.8%;
  }
  .similarityGraphImg img {
    border-radius: 8px;
    overflow: hidden;
  }
}
.similar-main-cont {
  // display: flex;
  width: 100%;
  height: 770px;
  margin: auto;
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;
  box-sizing: border-box;
}
@media screen and (max-width: 1280px) {
  .core-function {
    // .core-function_main_top {
    //   padding: 0;
    //   &::before {
    //     display: none;
    //   }
    // }
    .core-function_main_bottom {
      ul {
        column-gap: 3.8%;
        font-size: 16px;
      }
    }
  }
}
@media screen and (max-width: 990px) {
  .core-function {
    .core-function_main_top {
      padding: 0;
      &::before {
        display: none;
      }
    }
    /deep/ .common-two-column-light {
      .section-article-title,
      .section-article-desc {
        color: #000108;
      }
    }
    .core-function_main_bottom {
      ul {
        font-size: 14px;
      }
      // li {
      //   &:first-child {
      //     padding: 1.2% 0.4%;
      //   }
      // }
      // /deep/ .section-article-title {
      //   font-size: 14px;
      // }
      // /deep/.section-article-desc {
      //   color: #000108;
      //   font-size: 10px;
      //   margin-top: 0;
      // }
    }
  }
  .core-loading,
  .application-loading {
    display: none;
  }
  .application-scene {
    .application-scene_main_bottom {
      background: none;
      margin-top: 0;
    }
    /deep/ .application-scene_main_bottom .section-article {
      margin-top: 0;
    }
    /deep/ .common-two-column-light {
      .section-article-title,
      .section-article-desc {
        color: #000108;
      }
    }
  }
}
@media screen and (min-width: 1440px) {
  .header-custom {
    width: 1440px;
    margin: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 700px) {
  .core-function {
    .core-function_main_bottom {
      ul {
        font-size: 14px;
        bottom: 7%;
      }
      li {
        &:first-child {
          padding: 1.5% 0.7%;
        }
      }
      /deep/ .section-article-title {
        font-size: 14px;
      }
      /deep/.section-article-desc {
        color: #000108;
        font-size: 10px;
        margin-top: 0;
      }
    }
  }
}
</style>
